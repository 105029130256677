@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  src: local("Gilroy"), local("Gilroy"),
    url("../../fonts/Gilroy-FREE/Gilroy-ExtraBold.otf");
}

* {
  font-family: "Lato" !important;
  font-size: 1rem !important;
}
.company-name {
  font-family: "Gilroy" !important;
  font-size: 2rem !important;
}
/*Custom Font Sizes*/
h1 {
  font-size: 2rem !important;
}
h2 {
  font-size: 1.5rem !important;
}
h3 {
  font-size: 1.125rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.notificationHeight {
  height: 55.94px !important;
}
.bg-theme-grey {
  background-color: #fafbfe;
  box-shadow: none;
}

.lineHeight {
  line-height: 1;
}
.user-gravatar-width {
  width: 25px;
}
.iconSize {
  font-size: 22px !important;
}
.border-unset {
  border: unset !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

.pills-active.active {
  color: #727cf5 !important ;
  background-color: #fff !important;
  border-radius: unset;
  border: unset;
}
.align-edit-icon {
  margin-top: -35px;
  margin-right: 20px;
}
.align-delete-icon {
  margin-top: -35px;
}
.icon-hover-green:hover {
  color: #0acf97 !important;
}
.icon-hover-blue:hover {
  color: #4c71c5 !important;
}
.icon-hover-red:hover {
  color: #fa5c7c !important;
}
.btn-font-size {
  font-size: 1.1em;
}
.nav-link:hover,
.nav-link:focus {
  border-color: white !important;
}

.ellipses {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ellipses-invitation {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media only screen and (max-width: 1000px) and (min-width: 992px) {
  .ellipses-invitation {
    max-width: 130px;
  }
}
.font-size-20 {
  font-size: 20px !important;
}
.header-top {
  margin: 4px 0;
}
.table-hover tbody tr:hover {
  color: #6c757d;
  background-color: #f9f9f9 !important;
}

.no-table-hover:hover {
  background-color: white !important;
}
.badge-w-72 {
  width: 72px !important;
}
.table-hover-bg-color:hover {
  background-color: #f9f9f9 !important;
}
.loader {
  position: relative;
  left: 15%;
}
@media only screen and (max-width: 575px) {
  .inputWidth,
  .btnWidth {
    width: 100% !important;
  }
}

//Team tab Add Secret Existing
.list-scroll-style {
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
}
//list scroll bar design
.list-scroll-style::-webkit-scrollbar {
  width: 7px;
}
/* Track */
.list-scroll-style::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
/* Handle */
.list-scroll-style::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
/* Handle on hover */
.list-scroll-style::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.underlineHover:hover {
  text-decoration: underline;
}

.list-group-item {
  border: none !important;
}
.company-caption-height {
  margin-top: 10px;
}
.company-image-style {
  margin-top: -4px;
}

.name {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name:hover {
  overflow: visible;
}

.ellipses-width {
  white-space: nowrap;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-secret-list-ellipses-width {
  white-space: nowrap;
  width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1154px) and (min-width: 992px) {
  .ellipses-width {
    width: 110px;
  }
}
.account-user-name {
  padding-bottom: 1px !important;
  margin-top: 8px !important;
}
.account-user-avatar {
  margin-top: 3px !important;
}
.topbar-nav .side-nav-link {
  padding: 0.7rem 1.3rem !important;
}
.navbar-custom {
  min-height: 0px !important;
}
.nav-user {
  min-height: 0px !important;
  height: 63px !important;
}
.topnav {
  margin-top: 20px !important;
}
@media only screen and (max-width: 991px) {
  .topnav {
    margin-top: 10px !important;
  }
  .navbar-style {
    margin-top: 0px !important;
  }
}
.hamburger-style {
  height: 45px !important;
  margin-left: 3px !important;
}
.div-height {
  height: 18.89px !important;
}
.align-pass-copy-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.text-height {
  height: 19px;
}

.loader-style {
  margin-top: 70vh;
}
.timer-bar-length {
  margin-right: 98px;
}

.badge-width {
  width: 80px !important;
}

.card-width {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.4rem;
}
// .personal-secret-scroll > tbody {
//   display: block;
//   max-height: 700px;
//   overflow-y: auto;
//   min-height: 200px;
// }
// .user-table-scroll > tbody {
//   display: block;
//   max-height: 700px;
//   overflow-y: auto;
//   min-height: 200px;
// }
.personal-secret-scroll > thead,
.personal-secret-scroll > tbody tr,
.user-table-scroll > thead,
.user-table-scroll > tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* width */
.scroller-style::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scroller-style::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroller-style::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.scroller-style::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.team-list-scroll {
  max-height: auto;
  overflow-y: auto;
  display: block;
}
.box-shadow-style {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  z-index: 99;
}
.bg-color-blue {
  background-color: #146fbd !important;
}

.positon-style {
  top: -25px;
}
.progress-value-fontSize {
  font-size: 11px !important;
  font-weight: 800;
  color: #727cf5;
}
.progress-outer-style {
  box-shadow: unset !important;
  border: 1px solid #f1f3fa !important;
  height: 56px !important;
  width: 200px;
  background-color: #fafbfd !important;
  border-right-style: none !important;
  padding: 5px 20px !important;
}

.checklist-style {
  list-style-type: unset !important;
}

.dropdown-menu-position-style {
  width: 300px !important;
}
.progress-height {
  height: 1.4rem !important;
}

.progress-height {
  height: 1.4rem !important;
}

.toggle-label {
  position: relative;
  display: block;
  width: 225px;
  font-size: 12px;
  height: 30px;
  border: 1px solid #808080;
  border-radius: 360px;
  border: none;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}
.toggle-label input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
.toggle-label input[type="checkbox"] + .back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ddd;
  transition: background 200ms linear;
  border-radius: 360px;
  left: 0;
}
.toggle-label input[type="checkbox"]:checked + .back {
  background: #ddd;
  border-radius: 360px;
}
.toggle-label input[type="checkbox"] + .back .toggle {
  display: block;
  position: absolute;
  content: " ";
  background: #fff;
  width: 50%;
  height: 100%;
  transition: margin 200ms linear;
  border: 1px solid #808080;
  border-radius: 360px;
  border: none;
}
.toggle-label input[type="checkbox"]:checked + .back .toggle {
  margin-left: 112.5px;
}
.toggle-label .label {
  display: block;
  position: absolute;
  width: 50%;
  color: #000;
  line-height: 10px;
  text-align: center;
}
.toggle-label .label.on {
  left: 0px;
  line-height: 29px;
}
.toggle-label .label.off {
  right: 0px;
  line-height: 29px;
}
.toggle-label input[type="checkbox"]:checked + .back .label.on,
.toggle-label input[type="checkbox"]:checked + .back .label.on > a {
  color: #98a6ad;
}
.toggle-label input[type="checkbox"] + .back .label.off {
  color: #98a6ad;
}
.toggle-label input[type="checkbox"]:checked + .back .label.off {
  color: #000;
}
.label.on > a {
  text-decoration: none;
}
.list-style-none {
  list-style: none !important;
}
.email-list-scroll {
  height: 300px;
  overflow-y: auto;
}

.add-secret-list {
  height: 634.31px;
}

.add-secret-list-scroll {
  height: 400px;
  overflow-y: auto;
}
@media screen and (max-width: "1022px") {
  .add-secret-list {
    height: 682.31px;
  }
}
@media screen and (max-width: "1198px") and (min-width: "1023px") {
  .add-secret-list {
    height: 658.31px;
  }
}
@media screen and (min-width: "2076px") {
  .add-secret-list {
    height: 610.31px;
  }
}

@media screen and (max-width: "992px") {
  .border-radius-free-plan {
    border-radius: 15px !important;
  }
  .border-radius-startup-plan {
    border-radius: 15px !important;
  }
  .positon-style {
    top: 0px;
    margin-bottom: 0px !important;
  }
}
.border-radius-style-growth-plan {
  border-radius: 15px;
}
.border-radius-free-plan {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.border-radius-startup-plan {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.ellipses-onboarding-invitation {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.scroll-onboarding-invitation-list {
  height: 500px;
  overflow-y: auto;
}

.contact-list-scroll {
  min-height: 0;
  max-height: 243px;
  overflow-y: auto;
}

.onboarding-invitation-list {
  height: 600px;
}

.invite-user-card-height {
  height: 600px;
}
.margin-bottom-invite-email {
  margin-top: 3px;
}
.secret-list-items-ellipses,
.secret-list-items-ellipses-hostname {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}
@media only screen and (max-width: 525px) {
  .secret-list-items-ellipses-hostname {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
  }
}
@media (min-width: 576px) {
  .install-extension-modal-width {
    min-width: 600px !important;
  }
}
.carousel-indicators {
  top: 375px;
}
.carousel-indicators li {
  border-radius: 50% !important;
  width: 16px !important;
  height: 16px !important;
  background-color: #6c757d !important;
}
.carousel-control-next {
  right: -60px;
}
.carousel-control-prev {
  left: -60px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 25px !important;
  filter: invert(100%);
  padding: 2px !important;
}
@media (max-width: 767px) {
  .modal-backdrop.show {
    opacity: 0;
  }
}
.owner-name-ellipses {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media only screen and (max-width: 1210px) and (min-width: 992px) {
  .owner-name-ellipses {
    max-width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1376px) {
  .ellipses-existing-secrets-page-title {
    max-width: 270px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login {
    max-width: 200px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1120px) and (max-width: 1200px) {
  .ellipses-existing-secrets-page-title {
    max-width: 190px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login {
    max-width: 150px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 1119px) and (min-width: 991px) {
  .ellipses-existing-secrets-page-title {
    max-width: 150px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login {
    max-width: 120px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 991px) and (min-width: 769px) {
  .ellipses-existing-secrets-page-title {
    max-width: 230px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login {
    max-width: 190px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 768px) {
  .ellipses-existing-secrets-page-title {
    max-width: 120px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login {
    max-width: 100px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 530px) {
  .ellipses-existing-secrets-page-title {
    max-width: 80px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login {
    max-width: 70px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.ellipses-existing-secrets-page-title {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ellipses-existing-secrets-login {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user-list-name-ellipses {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user-list-email-ellipses {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.text-danger-strike-price {
  color: #f95656;
}

@media only screen and (max-width: 1337px) and (min-width: 1280px) {
  .title-font-size-style {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1279px) and (min-width: 1221px) {
  .title-font-size-style {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 1220px) and (min-width: 1163px) {
  .title-font-size-style {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 1162px) and (min-width: 1105px) {
  .title-font-size-style {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 1104px) and (min-width: 1047px) {
  .title-font-size-style {
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 1046px) and (min-width: 991px) {
  .title-font-size-style {
    font-size: 9px !important;
  }
}
.ellipses-existing-secrets-login-onBoardingProcess {
  max-width: 100px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ellipses-existing-secrets-page-title-onBoardingProcess {
  max-width: 110px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media only screen and (min-width: 1100px) and (max-width: 1170px) {
  .ellipses-existing-secrets-page-title-onBoardingProcess {
    max-width: 130px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login-onBoardingProcess {
    max-width: 110px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1171px) and (max-width: 1220px) {
  .ellipses-existing-secrets-page-title-onBoardingProcess {
    max-width: 150px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login-onBoardingProcess {
    max-width: 120px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1221px) {
  .ellipses-existing-secrets-page-title-onBoardingProcess {
    max-width: 170px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipses-existing-secrets-login-onBoardingProcess {
    max-width: 140px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.invitation-sent-icon-size {
  font-size: 36px !important;
}
.table-record-height {
  height: 90px;
}
.top-padding-dots {
  padding-top: 17px !important;
}
.add-user-card-min-height {
  min-height: 295px !important;
}
@media only screen and (min-width: 1221px) and (max-width: 1348px) {
  .tabs-font-size {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 1158px) and (max-width: 1220px) {
  .tabs-font-size {
    font-size: 13px !important;
  }
}
@media only screen and (min-width: 1094px) and (max-width: 1157px) {
  .tabs-font-size {
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 1030px) and (max-width: 1093px) {
  .tabs-font-size {
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 1029px) {
  .tabs-font-size {
    font-size: 10px !important;
  }
}

.select-account-icon {
  font-size: 16px !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #98a6ad;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.hover-none:hover {
  background-color: #fff !important;
}
.table-hover > tbody > tr.hover-none:hover {
  background-color: #fff !important;
}
